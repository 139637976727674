import api from '../../services/api';

const state = () => ({
    csrf: '',
    isAuthenticated: false,
})

const getters = {}

const actions = {
	async getCSRF({ commit, dispatch }) {
		try {
			commit('SET_LOADING', {
				module: 'auth',
				value: true,
			},
				{root: true}
			);
			const response = await api.get('/csrf/')
			const csrf = response.headers['x-csrftoken']
			commit('SET_CSRF', csrf);
			dispatch('getSession');
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		} finally {
			commit('SET_LOADING', {
				module: 'auth',
				value: false,
			},
				{root: true}
			);
		}
	},
	async getSession({ commit }) {
		try {

			const response = await api.get('/session/')

			if (response.data.isAuthenticated) {
				commit('SET_AUTHENTICATED', true);
			} else {
				commit('SET_AUTHENTICATED', false);
			}

		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		}
	},
}

const mutations = {
	SET_CSRF(state, csrf) {
		if (csrf !== '' || csrf !== undefined) {
			state.csrf = csrf;
		}
	},
	SET_AUTHENTICATED(state, auth) {
		state.isAuthenticated = auth;
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
	modules: {}
}