import { createApp } from "vue";
import { VueCookieNext } from 'vue-cookie-next'
import '@stripe/stripe-js';
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Toast, { PluginOptions } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

//import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faHeart, faUser, faShoppingCart, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faTwitter, faLinkedin, faInstagram, faYoutube, faMedium, faFacebookF, faPinterestP } from '@fortawesome/free-brands-svg-icons';

library.add(
	faFacebookSquare,
	faFacebookF,
	faPinterestP,
	faTwitter,
	faLinkedin,
	faInstagram,
	faYoutube,
	faMedium,
	faSearch,
	faTimes,
	faHeart,
	faUser,
	faShoppingCart,
	faStar,
);

createApp(App)
.use(store)
.use(router)
.use(VueCookieNext)
.use(Toast)
.component('font-awesome-icon', FontAwesomeIcon)
.mount("#app");

VueCookieNext.config({ expire: '7d' })