import api from '../../services/api';
import { formatProductList, formatProductDetail } from '../../services/formatting';
import { ProductDetailed, ProductList } from '../../types/interfaces';

const state = () => ({
		products: [] as ProductList,
		product: {} as ProductDetailed,
})

const getters = {
	products: state => state.products,
	product: state => state.product,
	productCount: state => state.products.length,
}

const actions = {
	async getProducts({ commit }) {
		try {
			commit('SET_LOADING', {
				module: 'products',
				value: true,
			},
				{root: true}
			);
			const response = await api.get('/products/');
			commit('ADD_PRODUCTS', response.data);
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		} finally {
			commit('SET_LOADING', {
				module: 'products',
				value: false,
			},
				{root: true}
			);
		}
	},
	async getProduct({ commit }, id) {
		try {
			commit('SET_LOADING', {
				module: 'products',
				value: true,
			},
				{root: true}
			);
			const response = await api.get(`/products/${id}/`)
			commit('ADD_PRODUCT', response.data);
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		} finally {
			commit('SET_LOADING', {
				module: 'products',
				value: false,
			},
				{root: true}
			);
		}
	},
	async getProductBySlug({ commit }, slug) {
		try {
			commit('SET_LOADING', {
				module: 'products',
				value: true,
			},
				{root: true}
			);
			const response = await api.get(`/products/${slug}/`)
			commit('ADD_PRODUCT', response.data);
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		} finally {
			commit('SET_LOADING', {
				module: 'products',
				value: false,
			},
				{root: true}
			);
		}
	},
};

const mutations = {
	ADD_PRODUCT(state, product) {
		state.product = formatProductDetail(product);
	},
	ADD_PRODUCTS(state, products) {
		state.products = formatProductList(products);
	},
	REMOVE_PRODUCT(state) {
		state.product = {};
	},
	REMOVE_PRODUCTS(state) {
		state.products = [];
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
	modules: {}
}