
import { defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'TheFooter',
  setup() {
    const formButtonText = ref('Subscribe');

    const store = useStore();

    const footerItems = reactive([
      {
        text: 'Contact',
        path: '/contact',
      },
      {
        text: 'Support',
        path: '/support',
      },
    ]);

    const icons = reactive([
      {
        type: 'fab',
        name: 'facebook-square',
        path: 'https://www.facebook.com/Thriftella-126411358898666',
      },
      {
        type: 'fab',
        name: 'twitter',
        path: 'https://twitter.com/thriftella',
      },
      {
        type: 'fab',
        name: 'youtube',
        path: 'https://www.youtube.com/channel/UCJIgdP5OwOMf7KyyQ4KLbIA',
      },
      {
        type: 'fab',
        name: 'instagram',
        path: 'https://www.instagram.com/thriftella.nyc/',
      },
    ]);

    const email = ref('');

    async function subscribe() {
      await store
        .dispatch('communications/sendSubscription', email.value)
        .then(() => {
          email.value = '';
          formButtonText.value = 'Subscribed!';

          setTimeout(() => {
            formButtonText.value = 'Subscribe';
          }, 3000);
        })
        .catch((error) => {
          window.alert('There was an error sending your request');
          email.value = '';
        });
    }

    return {
      subscribe,
      email,
      footerItems,
      icons,
      formButtonText,
    };
  },
});
