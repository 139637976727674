<template>
  <transition name="fade">
    <div
      class="alert alert-danger alert-dismissible"
      :class="{ show: triggerAlert }"
      role="alert"
      v-show="triggerAlert"
    >
      There was an error sending your request
      <span role="button" @click="close">
        <font-awesome-icon :icon="['fas', 'times']" />
      </span>
    </div>
  </transition>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ErrorMessage',
  setup() {
    const store = useStore();

    const triggerAlert = computed(() => store.state.triggerAlert);

    function close() {
      store.commit('TRIGGER_ALERT', false);
    }

    const error = computed(() => store.state.error);

    return {
      error,
      close,
      triggerAlert,
    };
  },
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
