
import { computed, defineComponent, ref, onBeforeMount, watch } from 'vue';
import { useStore } from 'vuex';
import TheNavbar from './components/TheNavbar.vue';
import TheFooter from './components/TheFooter.vue';
import ComingSoon from './views/ComingSoon.vue';
import ErrorMessage from './components/ErrorMessage.vue';

export default defineComponent({
  name: 'App',
  components: {
    TheNavbar,
    TheFooter,
    ComingSoon,
    ErrorMessage,
  },
  setup() {
    const comingSoon = ref(true);

    const userOnline = ref(navigator.onLine);

    const store = useStore();

    const getCSRF = async () => store.dispatch('auth/getCSRF');

    const getCart = async () => store.dispatch('cart/getCart');

    const cartCount = computed(() => store.getters['cart/cartCount']);

    const getPublishableKey = async () => store.dispatch('cart/getPublishableKey');

    async function init() {
      if (comingSoon.value === false) {
        await getCSRF().then(() => {
          getCart();
          getPublishableKey();
        });
      }
    }

    onBeforeMount(async () => await init());

    watch(
      () => cartCount.value,
      async (newAmount, prevAmount) => {
        if (prevAmount !== 0 && (newAmount == 0 || newAmount == undefined)) {
          await store.dispatch('cart/getCart');
        }
      },
    );

    return {
      comingSoon,
      userOnline,
      cart: computed(() => store.state.cart.cart),
      csrf: computed(() => store.state.auth.csrf),
      isAuthenticated: computed(() => store.state.auth.isAuthenticated),
      error: computed(() => store.state.error),
      publishableKey: computed(() => store.getters['cart/publishableKey']),
      productLoading: computed(() => store.getters['productsLoading']),
      cartLoading: computed(() => store.getters['cartLoading']),
      authLoading: computed(() => store.getters['authLoading']),
    };
  },
});
