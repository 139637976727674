import { nextTick } from "vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store"

// const Errors = () => import("../views/Errors.vue")
const Shop = () => import("../views/Shop.vue")
/*const Checkout = () => import("../views/Checkout.vue")
const ContactUs = () => import("../views/ContactUs.vue")
const FAQ = () => import("../views/FAQ.vue")
const OrderComplete = () => import("../views/OrderComplete.vue")
const ProductDetails = () => import("../views/ProductDetails.vue")
const ShippingReturns = () => import("../views/ShippingReturns.vue")
const Cart = () => import("../views/Cart.vue")*/

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: 'Shop',
    component: Shop,
    /*beforeEnter: async (to, from) => {
      try {
        await store.dispatch('products/getProducts');
      } catch(error) {
        const statusCode = store.state.error.statusCode;

        if (statusCode === '404') {
          router.push({
          name: 'Error404',
        })
        } else {
          router.push({
            name: 'Error500',
          })
        }
      }
    }*/
  },
  /*{
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
    import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/terms-conditions",
    name: "TermsConditions",
    component: () =>
    import("../views/TermsConditions.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import("../views/About.vue"),
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    beforeEnter: async (to, from) => {
      try {
        await store.dispatch('cart/getCart');
        const cartItemCount = store.getters['cart/cartItems'].length;

        if (cartItemCount === 0 || cartItemCount === undefined) {
          router.push(from)
        }
      } catch (error) {
        const statusCode = store.state.error.statusCode;

        if (statusCode !== '500') {
          router.push({
          name: 'Error404',
        })
        } else {
          router.push({
            name: 'Error500',
          })
        }

      }

    }
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactUs,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/order-complete",
    name: "OrderComplete",
    component: OrderComplete,
    props: true,
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.params).length === 0) {
        router.push(from)
      } else {
        next()
      }
    }
  },
  {
    path: "/products/:productId",
    name: "ProductDetails",
    component: ProductDetails,
    beforeEnter: async (to, from) => {
      try {
        console.log('before Enter Router');
        await store.dispatch('products/getProduct', to.params.productId);
      } catch (error) {
        const statusCode = store.state.error.statusCode;

        if (statusCode !== '500') {
          router.push({
          name: 'Error404',
        })
        } else {
          router.push({
            name: 'Error500',
          })
        }

      }

    }
  },
  {
    path: "/products/:productSlug",
    name: "ProductDetails",
    component: ProductDetails,
    beforeEnter: async (to, from) => {
      try {
        console.log('before Enter Router');
        await store.dispatch('products/getProductBySlug', to.params.productSlug);
      } catch (error) {
        const statusCode = store.state.error.statusCode;

        if (statusCode !== '500') {
          router.push({
          name: 'Error404',
        })
        } else {
          router.push({
            name: 'Error500',
          })
        }

      }

    }
  },
  {
    path: "/shipping-and-returns",
    name: "ShippingReturns",
    component: ShippingReturns,
  },
  {
    path: "/error500",
    name: "Error500",
    component: Errors,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error404",
    component: Errors,
  },*/
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }

  }
});

router.beforeEach((to, from) => {
  //console.log('router: ', router.currentRoute);
  //return false
})

export default router;
