import _ from 'lodash';

/*
* Name: formatAttributes(array<Object>)
? Process:
?  - Accepts array as parameter
* Returns:
*  - Array of updated objects or empty array
*/
function formatAttributes(array) {
  const innerArray: any[] = [];

  for (const item in array) {
    //* Add values of inner objects to new object
    const newObject = {
      category: array[item].category.name,
      value: array[item].value.name
    }
    innerArray.push(newObject);
  }

  return innerArray;

}

function formatAlternatives(array) {
  const newArray = _.cloneDeep(array);

  for (const item in newArray) {
    //* Add values of inner objects to new object
    if (newArray[item].attributes) {
      newArray[item].attributes = formatAttributes(array[item].attributes)
    }
  }

  return newArray;
}

/*
* Name: formatCartItems(array<Object>)
? Process:
?  - Accepts array as parameter
?  - Copy data to new array
* Returns:
*  - Array of updated objects or empty array
*/
function formatCartItems(array) {

  //* Clone of passed array to ensure no mutations of original data
  const newArray = _.cloneDeep(array)

  //* Loop through items in array
  for (const item in newArray) {
    //* If the item within the array has the key 'product':
    if (newArray[item].product) {
      //* Initialize object named 'product' by deep cloning object from array
      const product = _.cloneDeep(newArray[item].product);

      if (product.product === null) {
        //* Updates the content of array items 'product' object
        //* with variant object data
        newArray[item].product = Object.assign({}, product.variant);
      }
      else {
        newArray[item].product = Object.assign({}, product.product);
      }

      //* Product has an attributes array
      if (newArray[item].product.attributes) {
        newArray[item].product.attributes = formatAttributes(newArray[item].product.attributes);
      }

      //* Product has a productAttributes array
      if (newArray[item].product.productAttributes) {
        newArray[item].product.productAttributes = formatAttributes(newArray[item].product.productAttributes);

        //* Merge arrays if both exist
        if (newArray[item].product.attributes) {
          newArray[item].product.attributes = newArray[item].product.attributes.concat(newArray[item].product.productAttributes)
          delete newArray[item].product.productAttributes;
        }

      }
    }
  }

  return newArray;
}

function formatProductList(array) {
  //* Clone of passed array to ensure no mutations of original data
  const newArray = _.cloneDeep(array);

  //* Loop through items in array
  for (const item in newArray) {
    //* Product has an attributes array
    if (newArray[item].attributes) {
      newArray[item].attributes = formatAttributes(newArray[item].attributes);
    }
    if (newArray[item].alternatives) {
      //console.log("alternatives format: ", newArray[item].alternatives);
      newArray[item].alternatives = formatAlternatives(newArray[item].alternatives);
    }
    if (newArray[item].sku === null) {
      delete newArray[item].sku;
    }
  }

  return newArray;
}

function formatProductDetail(object) {
  const newObject = _.cloneDeep(object);

  if (newObject.attributes) {
    newObject.attributes = formatAttributes(newObject.attributes);
  }

  if (newObject.sku === null) {
    delete newObject.sku;
  }

  if (newObject.alternatives) {
    for (const item in newObject.alternatives) {
      newObject.alternatives[item] = formatProductDetail(newObject.alternatives[item]);
    }
  }

  if (newObject.variants) {
    newObject.variants = formatProductVariant(newObject.variants);
  }

  return newObject;
}

function formatProductVariant(array) {
  const newArray = _.cloneDeep(array);

  for (const item in newArray) {
    if (newArray[item].productAttributes && newArray[item].attributes) {
      newArray[item].attributes.push(...newArray[item].productAttributes)
      delete newArray[item].productAttributes;
    }

    newArray[item].attributes = formatAttributes(newArray[item].attributes);
  }

  return newArray
}

export { formatAttributes, formatCartItems, formatProductList, formatProductDetail, formatProductVariant };