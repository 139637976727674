import axios, { AxiosInstance } from 'axios';
import store from '../store/index';

const api: AxiosInstance = axios.create({
  baseURL: "http://localhost:8000/",
  withCredentials: true,
  responseType: 'json',
  timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((request) => {
  if(typeof request.headers['X-CSRFToken'] === 'undefined') {
		if (store.state['auth'].csrf !== '') {
			request.headers['X-CSRFToken'] = store.state['auth'].csrf;
		}
}
  return request;
});

export default api;