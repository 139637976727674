import api from '../../services/api';

const state = () => ({
})

const getters = {
}

const actions = {
	async sendContact({ commit }, contact) {
		try {
			commit('SET_LOADING', {
				module: 'communications',
				value: true,
			},
				{root: true}
			);
			const response = await api.post('marketing/contact/', {
				...contact
			})
			console.log(response);
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		}
		finally {
			commit('SET_LOADING', {
				module: 'communications',
				value: false,
			},
				{root: true}
			);
		}
	},
	async sendSubscription({ commit }, email) {
		try {
			commit('SET_LOADING', {
				module: 'communications',
				value: true,
			},
				{root: true}
			);
			const response = await api.post('marketing/subscribe/', {
				email
			})
			console.log(response);
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		}
		finally {
			commit('SET_LOADING', {
				module: 'communications',
				value: false,
			},
				{root: true}
			);
		}
	},
};

const mutations = {
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
	modules: {}
}