<template>
  <nav class="navbar navbar-expand navbar-dark">
    <div class="container">
      <!-- Brand -->
      <h4 class="navbar-brand">Thriftella</h4>

      <!-- Nav -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" v-for="icon in icons" :key="icon.name">
          <a :href="icon.path" target="_blank" class="nav-link">
            <font-awesome-icon :icon="[icon.type, icon.name]" />
          </a>
        </li>
      </ul>
    </div>
  </nav>
  <section
    class="bg-cover"
    style="
      margin-top: -102px;
      background-image: url(https://images.unsplash.com/photo-1517772520518-0d9d3eedac27?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3450&q=80);
    "
  >
    <!-- NAV -->

    <!-- CONTENT -->
    <div class="container d-flex flex-column">
      <div class="row align-items-center justify-content-center min-vh-100 pt-13 pb-12">
        <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center text-white">
          <!-- Heading -->
          <h1>We Are Coming Soon</h1>

          <!-- Text -->
          <p class="mb-9 font-size-lg">The future of affordable fashion is on the way, stay tuned! 👕 🦊 🐿.</p>

          <!-- Counter -->
          <!--<div class="d-flex justify-content-center mb-10" data-bs-countdown data-date="Jan 5, 2021 15:37:25">
            <div class="text-center">
              <div class="font-size-h1 font-weight-bolder" data-bs-days>00</div>
              <div class="heading-xxs">Days</div>
            </div>
            <div class="px-1 px-md-4">
              <div class="font-size-h2 font-weight-bolder">:</div>
            </div>
            <div class="text-center">
              <div class="font-size-h1 font-weight-bolder" data-bs-hours>00</div>
              <div class="heading-xxs">Hours</div>
            </div>
            <div class="px-1 px-md-4">
              <div class="font-size-h2 font-weight-bolder">:</div>
            </div>
            <div class="text-center">
              <div class="font-size-h1 font-weight-bolder" data-bs-minutes>00</div>
              <div class="heading-xxs">Minutes</div>
            </div>
            <div class="px-1 px-md-4">
              <div class="font-size-h2 font-weight-bolder">:</div>
            </div>
            <div class="text-center">
              <div class="font-size-h1 font-weight-bolder" data-bs-seconds>00</div>
              <div class="heading-xxs">Seconds</div>
            </div>
          </div>-->

          <!-- Form -->
          <!--<form @submit.prevent="sendSubscription">
            <div class="form-row">
              <div class="col-12 col-md">-->
          <!-- Input -->
          <!--<div class="form-group mb-md-0">
                  <label class="sr-only" for="comingSoonEmail"></label>
                  <input
                    class="form-control form-control-dark"
                    id="comingSoonEmail"
                    type="email"
                    placeholder="Enter Email *"
                    required
                    v-model="email"
                  />
                </div>
              </div>
              <div class="col-12 col-md-auto">-->
          <!-- Button -->
          <!--<button class="btn btn-dark" type="submit">Notify me!</button>
              </div>
            </div>
          </form>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ComingSoon',
  setup() {
    const email = ref('');
    const icons = reactive([
      {
        type: 'fab',
        name: 'facebook-square',
        path: 'https://www.facebook.com/Thriftella-126411358898666',
      },
      {
        type: 'fab',
        name: 'twitter',
        path: 'https://twitter.com/thriftella',
      },
      {
        type: 'fab',
        name: 'youtube',
        path: 'https://www.youtube.com/channel/UCJIgdP5OwOMf7KyyQ4KLbIA',
      },
      {
        type: 'fab',
        name: 'instagram',
        path: 'https://www.instagram.com/thriftella.nyc/',
      },
    ]);

    const store = useStore();

    async function sendSubscription() {
      await store.dispatch('communications/sendSubscription', email.value);
      console.log(email.value);
    }

    return {
      email,
      icons,
      sendSubscription,
    };
  },
});
</script>

<style scoped>
.navbar-brand {
  font-family: 'Leckerli One', cursive;
  letter-spacing: 1.5px;
  text-transform: capitalize;
}
</style>
