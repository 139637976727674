
import { defineComponent, ref, reactive } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ComingSoon',
  setup() {
    const email = ref('');
    const icons = reactive([
      {
        type: 'fab',
        name: 'facebook-square',
        path: 'https://www.facebook.com/Thriftella-126411358898666',
      },
      {
        type: 'fab',
        name: 'twitter',
        path: 'https://twitter.com/thriftella',
      },
      {
        type: 'fab',
        name: 'youtube',
        path: 'https://www.youtube.com/channel/UCJIgdP5OwOMf7KyyQ4KLbIA',
      },
      {
        type: 'fab',
        name: 'instagram',
        path: 'https://www.instagram.com/thriftella.nyc/',
      },
    ]);

    const store = useStore();

    async function sendSubscription() {
      await store.dispatch('communications/sendSubscription', email.value);
      console.log(email.value);
    }

    return {
      email,
      icons,
      sendSubscription,
    };
  },
});
