
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ErrorMessage',
  setup() {
    const store = useStore();

    const triggerAlert = computed(() => store.state.triggerAlert);

    function close() {
      store.commit('TRIGGER_ALERT', false);
    }

    const error = computed(() => store.state.error);

    return {
      error,
      close,
      triggerAlert,
    };
  },
});
