
import { defineComponent, computed, ref, reactive } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'TheNavbar',
  setup() {
    const store = useStore();

    const title = ref('Thriftella');
    const collapse = ref(false);
    const homeMenu = ref(false);
    const catalogMenu = ref(false);
    const shopMenu = ref(false);
    const pagesMenu = ref(false);
    const blogMenu = ref(false);
    const docsMenu = ref(false);
    const navbarItems = reactive([
      {
        text: '',
        path: '/',
      },
    ]);
    const dropdownItems = reactive([
      {
        text: '',
        path: '/',
      },
    ]);
    const icons = reactive([
      {
        type: 'fab',
        name: 'facebook-square',
        path: 'https://www.facebook.com',
      },
      {
        type: 'fab',
        name: 'twitter',
        path: 'https://www.twitter.com',
      },
      {
        type: 'fab',
        name: 'youtube',
        path: 'https://www.youtube.com',
      },
      {
        type: 'fab',
        name: 'instagram',
        path: 'https://www.instagram.com',
      },
      {
        type: 'fab',
        name: 'medium',
        path: 'https://www.medium.com',
      },
    ]);

    function enter() {
      const navbarCollapse = document.querySelector('.navbar-collapse');

      if (navbarCollapse) {
        if (!navbarCollapse.classList.contains('show')) {
          navbarCollapse.classList.add('show');
        } else {
          let toggle = document.querySelector('[data-toggle="collapse"]');
          if (toggle) {
            toggle.classList.add('hovered');
            toggle.classList.add('show');
          }
        }
      }
    }

    function leave() {
      const navbarCollapse = document.querySelector('.navbar-collapse');

      if (navbarCollapse) {
        if (navbarCollapse.classList.contains('show')) {
          let toggle = document.querySelector('[data-toggle="collapse"]');

          if (toggle) {
            toggle.classList.remove('show');
            toggle.classList.add('blur');
          }
        }
      }
    }

    function dropdown() {
      const navbarCollapse = document.querySelector('.navbar-collapse');
      const navbarDropdown = document.querySelectorAll('.navbar-nav .dropdown .navbar-nav .dropright');

      if (navbarCollapse) {
        if (navbarCollapse.classList.contains('show')) {
          let toggle = document.querySelector('[data-bs-toggle="dropdown"]');
        }
      }
    }

    return {
      title,
      collapse,
      homeMenu,
      catalogMenu,
      shopMenu,
      pagesMenu,
      blogMenu,
      docsMenu,
      navbarItems,
      dropdownItems,
      icons,
      enter,
      leave,
      dropdown,
      // access a getter in vuex
      cartCount: computed(() => store.getters['cart/cartCount']),
      // access a mutation in vuex
      triggerSidebar: () => store.commit('TRIGGER_SIDEBAR'),
      menuToggle: () => (collapse.value = !collapse.value),
    };
  },
});
