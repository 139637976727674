import { createStore, createLogger} from 'vuex';
import cart from './modules/cart';
import products from './modules/products';
import auth from './modules/auth';
import communications from './modules/communications';

export default createStore({
  state: {
    loading: {
      authLoading: false,
      productsLoading: false,
      cartLoading: false,
      communicationsLoading: false,
    },
    sidebar: false,
    error: {
      statusCode: '',
      message: '',
      details: '',
    },
    triggerAlert:  false,
  },
  mutations: {
    SET_ERROR(state, error) {
        state.error = {
        'statusCode' : error.response.status,
        'message' : error.message,
        'details' : error.response.data,
      };
    },
    SET_CHECKOUT_ERROR(state, error) {
      state.error = {
        'statusCode' : error.code,
        'message' : error.message,
        'details': '',
      };
    },
    SET_LOADING(state, status) {
      state.loading[`${status.module}Loading`] = status.value;
    },
    TRIGGER_SIDEBAR(state) {
      state.sidebar = !state.sidebar;
    },
    TRIGGER_ALERT(state, status) {
      state.triggerAlert = status;
    }

  },
  actions: {},
  getters: {
    productsLoading: state => state.loading.productsLoading,
    authLoading: state => state.loading.authLoading,
    cartLoading: state => state.loading.cartLoading,
    communicationsLoading: state => state.loading.communicationsLoading,
  },
  modules: {
    cart,
    auth,
    products,
    communications
  },
  /*plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []*/
});