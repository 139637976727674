
import api from '../../services/api';
import { formatCartItems } from '../../services/formatting';
import { Cart } from '../../types/interfaces';
import communications from './communications';

const state = () => ({
	cart: {} as Cart,
	stripePublishableKey: '' as string,
	clientSecret: '' as string,
	orderNumber: '' as string,
	checkoutProcessActive: false,
})

const getters = {
	cartCount: state => state.cart['totalQuantity'],
	cartItems: state => state.cart['items'],
	cart: state => state.cart,
	publishableKey: state => state.stripePublishableKey,
	clientId: state => {
		const regex = new RegExp('_secret');

		return state.clientSecret.split(regex)[0]
	}
}

const actions = {
	async getCart({ commit }) {
		try {
			commit('SET_LOADING', {
				module: 'cart',
				value: true,
			},
				{root: true}
			);
			const response = await api.get('/cart/')
			commit('ADD_CART', response.data);
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		} finally {
			commit('SET_LOADING', {
				module: 'cart',
				value: false,
			},
				{root: true}
			);
		}
	},
	async addToCart({ commit }, item) {
		try {
			commit('SET_LOADING', {
				module: 'cart',
				value: true,
			},
				{root: true}
			);
			console.log(item);
			const response = await api.post('/add_to_cart/', {
				...item,
				update: false
			})
			console.log(response);
			commit('ADD_CART', response.data);
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		} finally {
			commit('SET_LOADING', {
				module: 'cart',
				value: false,
			},
				{root: true}
			);
		}
	},
	async updateCart({ commit }, item) {
		try {
			commit('SET_LOADING', {
				module: 'cart',
				value: true,
			},
				{root: true}
			);

			const response = await api.post('/add_to_cart/', {
				...item,
				update: true
			})

			console.log(response);

			commit('ADD_CART', response.data);
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		} finally {
			commit('SET_LOADING', {
				module: 'cart',
				value: false,
			},
				{root: true}
			);
		}
	},
	async removeFromCart({ commit }, sku) {
		try {
			commit('SET_LOADING', {
				module: 'cart',
				value: true,
			},
				{root: true}
			);
			const response = await api.post('/remove_from_cart/', { sku: sku})
			console.log(response);
			commit('ADD_CART', response.data);
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		} finally {
			commit('SET_LOADING', {
				module: 'cart',
				value: false,
			},
				{root: true}
			);
		}
	},
	async checkout({ commit }, { shipping, billing, email, clientId}) {
		try {
		const response = await api.post('checkout/', {
					shipping,
					billing,
					email,
					clientId
		})

		return response;
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			console.log(error);
			throw error;
		}
	},
	async getPublishableKey({ commit }) {
		try {
			const response = await api.get('/get_publishable_key/')
			commit('ADD_PUBLISHABLE_KEY', response.data.publishable_key);
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		}
	},
	async createPaymentIntent({ commit }) {
		try {
			const response = await api.post('/create_payment_intent/')
			console.log(response)
			commit('ADD_CLIENT_SECRET', response.data)
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		}
	},
	async retrievePaymentIntentStatus({ commit }, clientId){
		try {
			const response = await api.get('/retrieve_payment_intent_status/', { params: { client_id: clientId}})
			console.log(response.data);
			return response.data
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		}
	},
	async getOrderNumber({ commit }, clientId){
		try {
			const response = await api.get('/get_order_number/', { params: { client_id: clientId}})
			commit('ADD_ORDER_NUMBER', response.data);
		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		}
	},
	async endSession({ commit }) {
		try {
			const response = await api.delete('/end_session/')

			return response.status

		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		}
	},
	async removeOrder({ commit }, orderNumber) {
		try {
			console.log("in store: ", orderNumber);
			const response = await api.delete('/remove_order/', { params: { order_number: orderNumber}})

			return response.status

		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		}
	},
	async cancelPaymentIntent({ commit }, clientId) {
		try {
			console.log("in store: ", clientId);
			const response = await api.post('/cancel_payment_intent/', { client_id: clientId })

			return response.status

		} catch (error) {
			commit('SET_ERROR', error, {root: true});
			throw error;
		}
	},
}

const mutations = {
	ADD_CART(state, cart) {
		if (cart.items.length > 0) {
			cart.items = formatCartItems(cart.items);
		}

		state.cart = cart;
	},
	ADD_ORDER_NUMBER(state, number) {
		state.orderNumber = number;
	},
	REMOVE_ORDER_NUMBER(state) {
		state.orderNumber = ''
	},
	REMOVE_CART(state) {
		state.cart = {};
	},
	ADD_PUBLISHABLE_KEY(state, key) {
		state.stripePublishableKey = key;
	},
	REMOVE_PUBLISHABLE_KEY(state) {
		state.stripePublishableKey = '';
	},
	ADD_CLIENT_SECRET(state, key) {
		state.clientSecret = key;
	},
	REMOVE_CLIENT_SECRET(state) {
		state.clientSecret = '';
	},
	SET_CHECKOUT_PROCESS(state, status) {
		state.checkoutProcessActive = status;
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
	modules: {}
}