<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom">
    <div class="container">
      <!-- Brand -->
      <router-link class="navbar-brand" to="/">{{ title }}</router-link>

      <!-- Toggler -->
      <div class="d-flex flex-row justify-content-evenly">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="ml-lg-n4 d-lg-none">
          <router-link class="nav-link" to="/cart">
            <template v-if="cartCount">
              <span :data-cart-items="cartCount">
                <i class="fe fe-shopping-cart"></i>
              </span>
            </template>
            <template v-else>
              <span>
                <i class="fe fe-shopping-cart"></i>
              </span>
            </template>
          </router-link>
        </div>
      </div>

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <!-- Nav -->
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Shop</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">About</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/faq">FAQ</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact">Contact</router-link>
          </li>
        </ul>

        <!-- Nav -->
        <ul class="navbar-nav flex-row-reverse">
          <li class="nav-item ml-lg-n4 d-none d-lg-block">
            <router-link class="nav-link" to="/cart">
              <template v-if="cartCount">
                <span :data-cart-items="cartCount">
                  <i class="fe fe-shopping-cart"></i>
                </span>
              </template>
              <template v-else>
                <span>
                  <i class="fe fe-shopping-cart"></i>
                </span>
              </template>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, computed, ref, reactive } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'TheNavbar',
  setup() {
    const store = useStore();

    const title = ref('Thriftella');
    const collapse = ref(false);
    const homeMenu = ref(false);
    const catalogMenu = ref(false);
    const shopMenu = ref(false);
    const pagesMenu = ref(false);
    const blogMenu = ref(false);
    const docsMenu = ref(false);
    const navbarItems = reactive([
      {
        text: '',
        path: '/',
      },
    ]);
    const dropdownItems = reactive([
      {
        text: '',
        path: '/',
      },
    ]);
    const icons = reactive([
      {
        type: 'fab',
        name: 'facebook-square',
        path: 'https://www.facebook.com',
      },
      {
        type: 'fab',
        name: 'twitter',
        path: 'https://www.twitter.com',
      },
      {
        type: 'fab',
        name: 'youtube',
        path: 'https://www.youtube.com',
      },
      {
        type: 'fab',
        name: 'instagram',
        path: 'https://www.instagram.com',
      },
      {
        type: 'fab',
        name: 'medium',
        path: 'https://www.medium.com',
      },
    ]);

    function enter() {
      const navbarCollapse = document.querySelector('.navbar-collapse');

      if (navbarCollapse) {
        if (!navbarCollapse.classList.contains('show')) {
          navbarCollapse.classList.add('show');
        } else {
          let toggle = document.querySelector('[data-toggle="collapse"]');
          if (toggle) {
            toggle.classList.add('hovered');
            toggle.classList.add('show');
          }
        }
      }
    }

    function leave() {
      const navbarCollapse = document.querySelector('.navbar-collapse');

      if (navbarCollapse) {
        if (navbarCollapse.classList.contains('show')) {
          let toggle = document.querySelector('[data-toggle="collapse"]');

          if (toggle) {
            toggle.classList.remove('show');
            toggle.classList.add('blur');
          }
        }
      }
    }

    function dropdown() {
      const navbarCollapse = document.querySelector('.navbar-collapse');
      const navbarDropdown = document.querySelectorAll('.navbar-nav .dropdown .navbar-nav .dropright');

      if (navbarCollapse) {
        if (navbarCollapse.classList.contains('show')) {
          let toggle = document.querySelector('[data-bs-toggle="dropdown"]');
        }
      }
    }

    return {
      title,
      collapse,
      homeMenu,
      catalogMenu,
      shopMenu,
      pagesMenu,
      blogMenu,
      docsMenu,
      navbarItems,
      dropdownItems,
      icons,
      enter,
      leave,
      dropdown,
      // access a getter in vuex
      cartCount: computed(() => store.getters['cart/cartCount']),
      // access a mutation in vuex
      triggerSidebar: () => store.commit('TRIGGER_SIDEBAR'),
      menuToggle: () => (collapse.value = !collapse.value),
    };
  },
});
</script>

<style>
a.navbar-brand {
  font-family: 'Leckerli One', cursive;
  letter-spacing: 1.5px;
  text-transform: capitalize;
}
</style>
