<template>
  <footer class="bg-dark bg-cover @@classList" style="background-image: url(./assets/img/patterns/pattern-2.svg)">
    <div class="py-12 border-bottom border-gray-700">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 col-xl-6">
            <!-- Heading -->
            <h5 class="mb-7 text-center text-white">Want product updates? Subscribe!</h5>

            <!-- Form -->
            <form class="mb-11" @submit.prevent="subscribe">
              <div class="form-row align-items-start">
                <div class="col">
                  <input
                    type="email"
                    class="form-control form-control-gray-700 form-control-lg"
                    placeholder="Enter Email"
                    v-model="email"
                    required
                  />
                </div>
                <div class="col-auto">
                  <button type="submit" class="btn btn-gray-500 btn-lg">{{ formButtonText }}</button>
                </div>
              </div>
              <p class="mb-3 mb-md-0 p-2 m-2 font-size-xxs text-white" style="text-align: center">
                We will never share your data as we respect your privacy 💙
              </p>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3">
            <!-- Heading -->
            <router-link to="/">
              <h4 id="footer-title" class="mb-6 text-white">Thriftella</h4>
            </router-link>

            <!-- Social -->
            <ul class="list-unstyled list-inline mb-7 mb-md-0">
              <li class="list-inline-item" v-for="icon in icons" :key="icon.name">
                <a :href="icon.path" target="_blank" class="text-white">
                  <font-awesome-icon :icon="[icon.type, icon.name]" />
                </a>
              </li>
            </ul>
          </div>

          <div class="col-6 col-sm">
            <!-- Heading -->
            <h6 class="heading-xxs mb-4 text-white">Support</h6>

            <!-- Links -->
            <ul class="list-unstyled mb-7 mb-sm-0">
              <li>
                <router-link class="text-gray-300" to="/contact">Contact Us</router-link>
              </li>
              <li>
                <router-link class="text-gray-300" to="/faq">FAQs</router-link>
              </li>
            </ul>
          </div>
          <div class="col-6 col-sm">
            <!-- Heading -->
            <router-link to="/">
              <h6 class="heading-xxs mb-4 text-white">Shop</h6>
            </router-link>
          </div>
          <div class="col-6 col-sm">
            <!-- Heading -->
            <h6 class="heading-xxs mb-4 text-white">Company</h6>

            <!-- Links -->
            <ul class="list-unstyled mb-0">
              <li>
                <router-link class="text-gray-300" to="/about">Our Story</router-link>
              </li>
              <li>
                <router-link class="text-gray-300" to="/terms-conditions">Terms & Conditions</router-link>
              </li>
              <li>
                <router-link class="text-gray-300" to="/privacy-policy">Privacy Policy</router-link>
              </li>
            </ul>
          </div>
          <div class="col-6 col-sm">
            <!-- Heading -->
            <h6 class="heading-xxs mb-4 text-white">Contact</h6>

            <!-- Links -->
            <ul class="list-unstyled mb-0">
              <li>
                <a class="text-gray-300" href="mailto:help@thriftella.com?subject=Help%20me%20Thriftella!"
                  >help@thriftella.com</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="py-6">
      <div class="container">
        <div class="row">
          <div class="col">
            <!-- Copyright -->
            <p class="mb-3 mb-md-0 font-size-xxs text-muted" style="text-align: left">
              © 2021 All rights reserved. Thriftella LLC.
            </p>
          </div>
          <div class="col-auto">
            <!-- Payment methods -->
            <img class="footer-payment" src="../assets/img/payment/mastercard.svg" alt="..." />
            <img class="footer-payment" src="../assets/img/payment/visa.svg" alt="..." />
            <img class="footer-payment" src="../assets/img/payment/amex.svg" alt="..." />
            <img class="footer-payment" src="../assets/img/payment/paypal.svg" alt="..." />
            <img class="footer-payment" src="../assets/img/payment/maestro.svg" alt="..." />
            <!--<img class="footer-payment" src="../assets/img/payment/klarna.svg" alt="..." />-->
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'TheFooter',
  setup() {
    const formButtonText = ref('Subscribe');

    const store = useStore();

    const footerItems = reactive([
      {
        text: 'Contact',
        path: '/contact',
      },
      {
        text: 'Support',
        path: '/support',
      },
    ]);

    const icons = reactive([
      {
        type: 'fab',
        name: 'facebook-square',
        path: 'https://www.facebook.com/Thriftella-126411358898666',
      },
      {
        type: 'fab',
        name: 'twitter',
        path: 'https://twitter.com/thriftella',
      },
      {
        type: 'fab',
        name: 'youtube',
        path: 'https://www.youtube.com/channel/UCJIgdP5OwOMf7KyyQ4KLbIA',
      },
      {
        type: 'fab',
        name: 'instagram',
        path: 'https://www.instagram.com/thriftella.nyc/',
      },
    ]);

    const email = ref('');

    async function subscribe() {
      await store
        .dispatch('communications/sendSubscription', email.value)
        .then(() => {
          email.value = '';
          formButtonText.value = 'Subscribed!';

          setTimeout(() => {
            formButtonText.value = 'Subscribe';
          }, 3000);
        })
        .catch((error) => {
          window.alert('There was an error sending your request');
          email.value = '';
        });
    }

    return {
      subscribe,
      email,
      footerItems,
      icons,
      formButtonText,
    };
  },
});
</script>

<style>
.footer-payment {
  padding: 0px 3px;
}
#footer-title {
  font-family: 'Leckerli One', cursive;
}
</style>
